.container {
  display: flex;
  justify-content: center;
  height: 224px;
  padding: 12px;
  background: #e97db1;
}
.camera {
  border-radius: 4px;
}
.circle {
  position: absolute;
  bottom: 20px;
}
.close {
  position: absolute;
  right: 12px;
}
