.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  background: #e97db1;
}
.input {
  margin-bottom: 0;
}
.icon {
  padding: 12px;
}
