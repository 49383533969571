@import url("https://fonts.googleapis.com/css2?family=Monoton&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #02b7dd;
  color: white;
}
select,
input {
  font-size: 16px;
}
button {
  background-color: #e97db1 !important;
  border-color: #e97db1 !important;
}
.title {
  font-family: "Monoton", cursive;
  font-size: 11vw;
  text-align: center;
}
