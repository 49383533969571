.container {
  background: #443a84;
  padding: 20px;
  height: 70vh;
  overflow-y: scroll;
  margin-top: 30px;
  border-radius: 4px;
}
.header {
  margin: 10px 0 2px 0;
  color: #e97db1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
.vote {
  display: flex;
  justify-content: space-between;
}
.left {
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.right {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 10px;
}
