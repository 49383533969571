.container {
  position: fixed;
  top: 30%;
  width: 100vw;
  background: #443a84;
  padding: 20px 30px;
}
@media screen and (min-width: 550px) {
  .container {
    padding: 20px 30px 20px 20vw;
  }
}
.shadow {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.8;
  background-color: black;
}
