.container {
  background: #443a84;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 18px;
}
.shadow {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.8;
  background-color: black;
}
.closeIcon {
  display: flex;
  justify-content: flex-end;
  padding: 12px;
}
.popup {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 10px 10px 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.alert {
  position: absolute;
  left: 6px;
  top: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}
